import { useStaticQuery, graphql } from 'gatsby'
import { sort } from '@helpers/SingleWork'
import { WorkNode, WorkObject } from 'types'

const getFormattedWorks = (array: { node: WorkNode }[]): WorkObject[] => {
  const works: WorkObject[] = []

  array.map(({ node }) => {
    const { id, tags, entryTitle, color, slug, excerptFIrst, backgroundImage, position } = node

    const Work: WorkObject = {
      id,
      tags,
      color,
      slug,
      position,
      title: entryTitle,
      text: excerptFIrst.excerptFIrst,
      image: backgroundImage.file.url,
    }

    return works.push(Work)
  })

  return sort(works)
}

export const useWorks = (): WorkObject[] => {
  const graphData = useStaticQuery(graphql`
    query allWorksQuery {
      allContentfulPortfolioItem {
        edges {
          node {
            id
            position
            tags
            slug
            entryTitle
            excerptFIrst {
              excerptFIrst
            }
            color
            backgroundImage {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const { edges } = graphData.allContentfulPortfolioItem
  const data = getFormattedWorks(edges)
  return data
}
